"use strict";

const AwsExchangeKeyBucketName = "exchangekey.arkio.is";
const AwsExchangeIndexBucketName = "exchangeindex.arkio.is";
const AwsExchangeFileBucketName = "exchangefile.arkio.is";
const AwsExchangeUnityBucketName = "exchangeunity.arkio.is";

const { S3Client, PutObjectCommand, GetObjectCommand, ListObjectsCommand, DeleteObjectCommand, DeleteObjectsCommand } = require("@aws-sdk/client-s3");
const IndexFile = require("./IndexFile");
const CryptoUtil = require("./CryptoUtil.js");
const DataUtil = require("./DataUtil.js");
const Util = require("./Util.js");
const Constants = require("./Constants.js");
const FileSaver = require("file-saver");

const getAWSCred = function() {
	let k = new Uint8Array([192, 145, 88, 100, 32, 67, 220, 176, 12, 254, 141, 72, 17, 90, 105, 10,
		209, 121, 64, 100, 32, 67, 220, 176, 12, 254, 141, 72, 17, 222, 69, 135]);
	let h = "552F98DB3E53EB9EBAF6EE78FD839A8EC80512E4";
	let aki0 = 'GlSA5aCi9ptu27siWkjM0I';
	let aki1 = '8kJyUu5Wb7zHQ0L0QfIUo=';
	let sak0 = 'rPdLSMKvW8M7o9YqIJrvaHnU7tQrUeos';
	let sak1 = '5+6I9FlaWEMhzhTUM9i2COFEuk2xA5OV';
	return {
    	accessKeyId: CryptoUtil.decryptString(k, h, aki0 + aki1),
    	secretAccessKey: CryptoUtil.decryptString(k, h, sak0 + sak1)
	};
};

// The S3 client for interacting with amazon
const s3 = new S3Client({
  region: "us-east-2",
  credentials: getAWSCred()
});

// Get index object name
const getIndexObjectName = function(encryptionKey)
{
	var keyBase64 = CryptoUtil.bytesToBase64(encryptionKey);
	var indexObjectName = "IB/" + keyBase64;
	return indexObjectName;
};

// Method for calculating the needed size of parts
// when splitting an array into n smaller parts
// totalLength : The length of the array
// n : The number of parts
// Example if totalLength is 10 and n is 3 then 4 should be returned
// if totalLength is 9 and n is 3 then 3 should be returned
const getBytePartSize = function(totalLength, n)
{
	if (totalLength < n)
	{
		return 1;
	}
	var partSize = Math.floor(totalLength / n);
	return (totalLength % n) == 0 ? partSize : partSize + 1;
};

// Get sub array in an array of a bytes
// bytes : The original byte array
// index : Index where to start
// maxLength : Maximum length of the sub array.
// In many cases the returned array will have this length
// but in case maxLength would result in getting values out of the bounds
// of the array the length of the returned array will be smaller
const getSubByteArray = function(bytes, index, maxLength)
{
	var byteLen = bytes.length;
	if (index < byteLen)
	{
		var endIndex = index + maxLength - 1;
		var maxIndex = byteLen - 1;
		if (endIndex > maxIndex)
		{
			endIndex = maxIndex;
		}
		var output = [];
		for (var i = index; i <= endIndex; i++)
		{
			output.push(bytes[i]);
		}
		return new Uint8Array(output);
	}
	else
	{
		// index not within array
		return null;
	}
};

// Takes in array of Uint8Array objects
// and joins them into one big Uint8Array
// object
const joinBytes = function(bytes)
{
	var arrayCount = bytes.length;

	// Compute the size needed
	var size = 0;
	var i = 0;
	for (i = 0; i < arrayCount; i++)
	{
		size += bytes[i].length;
	}

	// Create new Uint8Array for the joined bytes
	var joinedBytes = new Uint8Array(size);
	var nextIndex = 0;
	for (i = 0; i < arrayCount; i++)
	{
		if (i == 0)
		{
			joinedBytes.set(bytes[i]);
		}
		else
		{
			joinedBytes.set(bytes[i], nextIndex);
		}
		nextIndex += bytes[i].length;
	}
	return joinedBytes;
};

// Get an object from the cloud given a bucket name and the key of the object
// in that bucket
const getObject = async (bucketName, key) => {
	console.log('Getting object from ' + bucketName);
	var result = {};
	result.success = false;
	try
	{
		const bucketParams = {
			Bucket: bucketName,
			Key: key,
			ResponseCacheControl: 'no-store'
		};

		const data = await s3.send(new GetObjectCommand(bucketParams));
		const statusCode = data.$metadata.httpStatusCode;
		console.log('getObject status code=' + statusCode);
		result.httpStatusCode = statusCode;

	  	if (statusCode == 200)
	  	{
			// Getting content of object from bucket
			result.objectBytes = await DataUtil.streamToBuffer(data.Body);
			result.success = true;
			return result;
	  	}
	}
	catch (err)
	{
		if (err.$metadata != null)
		{
			if (err.$metadata.httpStatusCode != null)
			{
				result.httpStatusCode = err.$metadata.httpStatusCode;
			}
		}

		if (result.httpStatusCode === 404)
		{
			console.log("Did not find object in " + bucketName);
		}
		else
		{
			console.error('Error in getObject: ', err);
		}
	}
	return result;
};

// Downloads object in multiple parts
// in parallel from file bucket
// Returns joined bytes of all the parts
const getMultiPartObjectBytes = async (hash, nrOfParts) =>
{
	var result = {};
	result.success = false;

	try
	{
		var tasks = [];
		for (var i = 0; i < nrOfParts; i++)
		{
			var partName = hash + "." + i;
			tasks.push(getObject(AwsExchangeFileBucketName, partName));
		}

		// Executes all the tasks in parallel
		const responses = await Promise.all(tasks);

		var byteArrays = [];
		for (let response of responses)
		{
			if (!response.success)
			{
				return result;
			}
			byteArrays.push(response.objectBytes);
		}

		var joinedBytes = joinBytes(byteArrays);

		result.joinedBytes = joinedBytes;
		result.success = true;
		return result;
	}
	catch(err)
	{
		console.error('Error in getMultiPartObjectBytes: '. err);
	}
	return result;
};

// Method for uploading an object to aws s3
// bucketName : name of the bucket
// objectName : name of the object
// bytes : The bytes to send
const putObjectRaw = async (bucketName, objectName, bytes) => {
	console.log("Sending data to " + bucketName);
	var result = {};
	result.success = false;
	var data = new Uint8Array(bytes);
    const uploadParams = {
		Bucket: bucketName,
		Key: objectName,
		Body: data
	};
	try {
		const putRes = await s3.send(new PutObjectCommand(uploadParams));
		result.httpStatusCode = putRes.$metadata.httpStatusCode;
		if (result.httpStatusCode === 200)
		{
			result.success = true;
			console.log("Successfully uploaded data to cloud.");
		}
	} catch (err) {
		console.error('Error in putObjectRaw: ', err);
	}
	return result;
};

const putObjectToUnityBucketRaw = async (objectName, bytes) => {
	var putRes = await putObjectRaw(AwsExchangeUnityBucketName, objectName, bytes);
	if (putRes.success)
	{	
		console.log("Uploaded file to unity bucket " + objectName + ", " + bytes.length + " bytes.");
		return true;
	}
	return false;
};

// For uploading a file to the file bucket on the cloud
// Uploads data in multiple parts
// encryptionKey : Encryption key for encrypting the data
// objectName : Name of the object in the cloud
// fileInfo : Object with info about the file
// fileCount: Number of files that were uploaded
// In case a single zip file was uploaded and nothing
// else it should be the number of files inside that zip
// bytes : The data to send
// onMessage : Method for showing messages
const putObject = async (encryptionKey, objectName, fileInfo, fileCount, bytes, onMessage) => {

	var result = {};
	result.success = false;

	try
	{
		// Encrypt the data before sending it to the cloud
		onMessage("Encrypting data...");
		var dataToSend = CryptoUtil.encryptObjectData(encryptionKey, objectName, bytes);

		// Number of parts for multipart upload
		var nrOfParts = Constants.MultipartNrOfParts;

		// Split data into parts and create an uploading task for each part
		var tasks = [];
		var partSize = getBytePartSize(dataToSend.length, nrOfParts);

		onMessage("Sending data to cloud...");
		for (var i = 0; i < nrOfParts; i++)
		{
			var partBytes = getSubByteArray(dataToSend, i * partSize, partSize);
			var partObjectName = objectName + "." + i;
			tasks.push(putObjectRaw(AwsExchangeFileBucketName, partObjectName, partBytes));
		}

		// Executes all the tasks in parallel
		const responses = await Promise.all(tasks);

		for (let response of responses)
		{
			if (!response.success)
			{
				// If one fails then we consider this to be a failure
				return result;
			}
		}

		// Add entry to the index file
		var entry = createAndInitializeIndexFileEntry();
		entry.File = {};
		entry.File.Filename = fileInfo.mainFile;
		entry.File.FileCount = fileCount;
		entry.File.Icon = fileInfo.icon;
		entry.File.NrOfParts = nrOfParts;
		entry.File.TotalNrOfBytes = dataToSend.length;
		entry.File.ArkioImport = {};
		result.entry = entry;
		
		onMessage("Updating index file...");
		var indexRes = await addToIndex(objectName, entry, encryptionKey);

		if (indexRes.indexFile != null)
		{
			result.indexFile = indexRes.indexFile;
		}

		// If adding the entry to the index file fails then we can consider this
		// to have failed because there is no use in just uploading the file
		// if it's entry does not get added to the index file
		result.success = indexRes.success;

	}
	catch(err)
	{
		console.error('Error in putObject: ', err);
	}

	return result;
};

// Get index file from cloud
// success parameter is set to true if an index file was retrieved successfully
// If success is false, that may happen because of a few reasons, e.g. if index
// does not exist because it has not been uploaded yet or because of some error
// use httpStatusCode to check what kind of error happened
const getIndexFromCloud = async(encryptionKey) =>
{
	var result = {};
	result.success = false;

	try
	{
		var indexObjectName = getIndexObjectName(encryptionKey);
		console.log("Getting current index for " + indexObjectName);

		const getRes = await getObject(AwsExchangeIndexBucketName, indexObjectName);
		result.httpStatusCode = getRes.httpStatusCode;

		if (getRes.success)
		{
			var indexData = getRes.objectBytes;
			result.indexFile = new IndexFile();
			result.indexFile.Deserialize(indexData);
			// Removes entries from index file that have expired, because we don't want those
			result.indexFile.RemoveExpiredEntries();
			result.success = true;
			console.log('Got index file from cloud.');
		}
		else
		{
			// If the status code is 404 then the index file was not found
			// If it was not 404 then some error has happened and it is
			// safest to abort the whole process of adding to the index
			if (getRes.httpStatusCode !== 404)
			{
				return result;
			}
		}
	}
	catch(err)
	{
		console.error('Error in getIndexFromCloud: ', err);
	}
	return result;
};

// Creates a new cloud index file entry object
// and initializes it with the Created and Expires dates set
const createAndInitializeIndexFileEntry = function() {
	let entry = {};
	entry.Created = Util.getCurrentFormattedUTCDate();
	let expires = new Date();
	expires.setMilliseconds(expires.getMilliseconds() + Constants.EntryLifespan);
	entry.Expires = Util.getFormattedUTCDate(expires);
	return entry;
};

// Add entry to index file in the cloud
const addToIndex = async(hash, entry, encryptionKey) =>
{
	var result = {};
	result.success = false;

	try
	{
		var indexRes = await getIndexFromCloud(encryptionKey);

		var indexFile = null;

		if(indexRes.success)
		{
			indexFile = indexRes.indexFile;
		}
		else if (indexRes.httpStatusCode === 404) // index file not found
		{
			// Create new index file
			indexFile = new IndexFile();
		}

		if (indexFile != null)
		{
			// Remove expired entries as we don't want them there
			indexFile.RemoveExpiredEntries();

			indexFile.Entries[hash] = entry;
			result.indexFile = indexFile;
			var newIndexBytes = indexFile.Serialize();
	
			var indexObjectName = getIndexObjectName(encryptionKey);
			console.log("Uploading index file to cloud.");
			var putRes = await putObjectRaw(AwsExchangeIndexBucketName, indexObjectName, newIndexBytes);
			if (putRes.success)
			{	
				console.log("Uploaded index file " + indexObjectName + ", " + newIndexBytes.length + " bytes.");
				result.success = true;
			}
		}
		else
		{
			return result;
		}
	}
	catch(err)
	{
		console.error('Error in addToIndex: ', err);
	}
	return result;
};

// Remove entry from index
const removeFromIndex = async(entryHash, encryptionKey) =>
{
	var result = {};
	result.success = false;

	try
	{
		var indexRes = await getIndexFromCloud(encryptionKey);
		var indexFile = null;
		if(indexRes.success)
		{
			indexFile = indexRes.indexFile;
		}
		else if (indexRes.httpStatusCode === 404) // index file not found
		{
			// If index file is not found, then nothing needs to be removed
			result.success = true;
		}

		if (indexFile != null)
		{
			// Remove the entry
			indexFile.RemoveEntry(entryHash);
			result.indexFile = indexFile;
			var newIndexBytes = indexFile.Serialize();
			var indexObjectName = getIndexObjectName(encryptionKey);
			console.log("Uploading index file to cloud.");
			var putRes = await putObjectRaw(AwsExchangeIndexBucketName, indexObjectName, newIndexBytes);
			if (putRes.success)
			{	
				console.log("Uploaded index file " + indexObjectName + ", " + newIndexBytes.length + " bytes.");
				result.success = true;
			}
		}
	}
	catch(err)
	{
		console.error('Error in removeFromIndex: ', err);
	}
	return result;
};

// Set the group name in the index file
const setGroupName = async function(groupName, encryptionKey)
{
	var result = {};
	result.success = false;

	try
	{
		var indexRes = await getIndexFromCloud(encryptionKey);
		var indexFile = null;

		if(indexRes.success)
		{
			indexFile = indexRes.indexFile;
		}
		else if (indexRes.httpStatusCode === 404) // index file not found
		{
			// Create new index file
			indexFile = new IndexFile();
		}

		if (indexFile != null)
		{
			indexFile.GroupName = groupName;
			var newIndexBytes = indexFile.Serialize();
	
			var indexObjectName = getIndexObjectName(encryptionKey);
			console.log("Uploading index file to cloud.");
			var putRes = await putObjectRaw(AwsExchangeIndexBucketName, indexObjectName, newIndexBytes);
			if (putRes.success)
			{	
				console.log("Uploaded index file " + indexObjectName + ", " + newIndexBytes.length + " bytes.");
				result.success = true;
			}
		}
		else
		{
			return result;
		}
	}
	catch(err)
	{
		console.error('Error in setGroupName: ', err);
	}
	return result;
};

// Verify that an entry is in the index file
// Polls the index file until entry is found or maxWaitingTime is reached
// entryHash : The hash of the entry to check
// encryptionKey : Encryption key for accessing the index file
//
// Returns object with the following properties:
// success : true if it was verified that the entry exists in the index file
// indexFile : The last index file object that was retrieved from the cloud
// in case it was verified.
const verifyIndexEntry = async function(entryHash, encryptionKey)
{
	let result = {};
	result.success = false;
	let startTime = new Date();
	try
	{
		let curTime = new Date();
		while ((curTime - startTime) < Constants.VerificationTimeout)
		{
			console.log('verifyIndexEntry request');
			let indexRes = await getIndexFromCloud(encryptionKey);
	
			if(indexRes.success)
			{
				let indexFile = indexRes.indexFile;

				// See if we can find the entry
				if (entryHash in indexFile.Entries)
				{
					// The entry is now in the index in the cloud
					console.log('Index entry verified');
					result.success = true;
					result.indexFile = indexFile;
					return result;
				}
			}

			await Util.sleep(Constants.VerificationSleepTime);
			curTime = new Date();
		}
	}
	catch(err)
	{
		console.error('Error in verifyIndexEntry:', err);
	}
	return result;
};

// Polls cloud until group name is verified to be groupName
// groupName : The expected group name
// encryptionKey : Encryption key decrypting data from cloud
// Returns true if successfully verfied else false
const verifyGroupName = async function(groupName, encryptionKey)
{
	let startTime = new Date();
	try
	{
		let curTime = new Date();
		while ((curTime - startTime) < Constants.VerificationTimeout)
		{
			console.log('verifyGroupName request');
			let indexRes = await getIndexFromCloud(encryptionKey);
	
			if(indexRes.success)
			{
				if (indexRes.indexFile.GroupName == groupName)
				{
					console.log('Group name verified');
					return true;
				}
			}
			await Util.sleep(Constants.VerificationSleepTime);
			curTime = new Date();
		}
	}
	catch(err)
	{
		console.error('Error in verifyGroupName:', err);
	}
	return false;
};

// Create an invite code for an already existing group
const getNewInviteCode = async(encryptionKey) =>
{
	var result = {};
	result.success = false;

	try
	{
		var inviteCode = CryptoUtil.createRandomInviteCode();
		var objectName = "KEB/" + inviteCode;

		var putRes = await putObjectRaw(AwsExchangeKeyBucketName, objectName, encryptionKey);

		if (putRes.success)
		{
			result.inviteCode = inviteCode;
			result.success = true;
			return result;
		}
	}
	catch(err)
	{
		console.error('Error in getNewInviteCode', err);
	}
	return result;
};

// Create new group in the cloud
// Get the encryption key for it
// and a 6 character invite code
const createGroup = async() =>
{
	var result = {};
	result.success = false;

	try
	{
		// Create new encryption key
		var encryptionKey = CryptoUtil.createRandomEncryptionKey();
		var inviteRes = await getNewInviteCode(encryptionKey);

		if (inviteRes.success)
		{
			result.encryptionKey = encryptionKey;
			result.inviteCode = inviteRes.inviteCode;
			result.success = true;
			return result;
		}
	}
	catch(err)
	{
		console.error('Error in createGroup: ', err);
	}
	return result;
};

// Makes request to the cloud to get a long encryption key
// in exchange for a 6 letter keycode
const getLongKey = async(keycode) =>
{
	var result = {};
	result.success = false;

	const objectName = "KEB/" + keycode;
	const getObjectResult = await getObject(AwsExchangeKeyBucketName, objectName);
	result.httpStatusCode = getObjectResult.httpStatusCode;

	if (getObjectResult.success)
	{
		result.encryptionKey = getObjectResult.objectBytes;
		result.success = true;
	}
	return result;
};

// For downloading a cloud resource in a browser
// Will download object with name objectName
// Uses multipart downloading
// Takes care of decrypting the data
const getCloudFile = async(objectName, encryptionKey, nrOfParts) =>
{
	var result = {};
	result.success = false;

	try
	{
		const getRes = await getMultiPartObjectBytes(objectName, nrOfParts);

		if (getRes.success)
		{
			// Need to decrypt the data
			var encryptedBytes = getRes.joinedBytes;
			var decryptedBytes = CryptoUtil.decryptObjectData(encryptionKey, objectName, encryptedBytes);
			result.decryptedBytes = new Uint8Array(decryptedBytes);
			result.success = true;
			return result;
		}
	}
	catch(err)
	{
		console.error('Error in getCloudFile: ', err);
	}

	return result;
};

// Opens save dialog to save bytes in a file
// bytes should be Uint8Array
// example of dataType for zip file: "application/zip"
// example filename: 'download.zip'
const saveBytesAsFile = function(bytes, dataType, filename)
{
	var blob = new Blob([bytes], { type: dataType });
	FileSaver.saveAs(blob, filename);
};

// Sort an array of entries by date
// newest first
const sortEntriesByDate = function(entries)
{
	const compareMethod = function(a, b)
	{
		let aCreated = Util.cloudEntryDateStringToDate(a.entryValue.Created);
		let bCreated = Util.cloudEntryDateStringToDate(b.entryValue.Created);
		let diff = bCreated - aCreated;
		return diff;
	};
	entries = entries.sort(compareMethod);
	return entries;
};

module.exports = {
	getObject : getObject,
	putObject : putObject,
	addToIndex : addToIndex,
	putObjectRaw : putObjectRaw,
	getNewInviteCode : getNewInviteCode,
	createGroup : createGroup,
	verifyGroupName : verifyGroupName,
	verifyIndexEntry : verifyIndexEntry,
	getLongKey : getLongKey,
	getIndexFromCloud : getIndexFromCloud,
	setGroupName : setGroupName,
	getCloudFile : getCloudFile,
	saveBytesAsFile : saveBytesAsFile,
	sortEntriesByDate : sortEntriesByDate,
	getIndexObjectName : getIndexObjectName,
	putObjectToUnityBucketRaw : putObjectToUnityBucketRaw,
	removeFromIndex : removeFromIndex,
	createAndInitializeIndexFileEntry : createAndInitializeIndexFileEntry
};
