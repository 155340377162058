// Methods for using services related to Autodesk Construction Cloud

// General method for making a GET request with some headers
const fetchDataWithHeader = async function(url, headersToUse) {
    try {
        const response = await fetch(url, { method: 'GET', headers: headersToUse });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('fetchDataWithHeader, error fetching data from ' + url, error);
        throw error;
    }
};

// General method for making a GET request to APS that requires a token
const fetchDataFromAPS = async function(url, token)
{ return await fetchDataWithHeader(url, {'Authorization': 'Bearer ' + token}); };

// Common method for making get requests to the an APS backend service
const getFromAPSBackendService = async function(pathEnding, params) {
	let url = new URL(Constants.APSBackendServicesBaseURI + pathEnding);
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
	return await fetchDataWithHeader(url.toString(), {'Accept': 'application/json' });
};

// Get APS client id from the backend service
const getClientId = async function()
{ return await getFromAPSBackendService('/arkio/getclientid', {}); };

// Get access token for accessing the data of some user on ACC
const request3LeggedAccessToken = async function(authCode, redirectUri)
{ return await getFromAPSBackendService('/arkio/accesstoken3legged', { 'code': authCode, 'redirecturi': redirectUri }); };

//REMARK I think it should be possible to do this by directly calling the APS
// service from the cloud portal instead of using the heroku server in the middle
// The only reason why the heroku server is used in request3LeggedAccessToken
// is because the client secret is needed for that.
// Get new access token using a refresh token
const regenerateAccessToken = async function(refreshToken)
{ return await getFromAPSBackendService('/arkio/regeneratetoken', { 'refreshtoken': refreshToken}); };

// Revoke an access token and refresh token
const revokeToken = async function(accessToken, refreshToken)
{ return await getFromAPSBackendService('/arkio/revoketoken', { 'token': accessToken, 'refreshtoken' : refreshToken }); };

// Start an export from revti design automation job
const startRevitExport = async function(userId, bimToken, filename, downloadUrl) {
	return await getFromAPSBackendService('/arkio/exportfromrevit',{
		'userid': userId,
		'bimtoken': bimToken,
		'filename': filename,
		'downloadurl': downloadUrl
	});
};

// Get info on design automation jobs
// jobIds : Should be a string with comma separated job ids
const getJobInfo = async function(jobIds)
{ return await getFromAPSBackendService('/arkio/job/rvttoarkiobyjobid', { 'jobids': jobIds }); };

// Get hubs on BIM360
const getHubs = async function(token)
{ return await fetchDataFromAPS('https://developer.api.autodesk.com/project/v1/hubs', token); };

// Get projects in a hub on BIM360
const getProjects = async function(token, hubId)
{ return await fetchDataFromAPS('https://developer.api.autodesk.com/project/v1/hubs/' + hubId + '/projects', token); };

// Get folders inside a project on BIM360
const getFolders = async function(token, hubId, projectId)
{ return await fetchDataFromAPS('https://developer.api.autodesk.com/project/v1/hubs/' + hubId + '/projects/' + projectId + '/topFolders', token); };

// Get folder contents on BIM360
const getFolderContent = async function(token, projectId, folderId)
{ return await fetchDataFromAPS('https://developer.api.autodesk.com/data/v1/projects/' + projectId + '/folders/' + folderId + '/contents', token); };

// Get the tip (latest) version of an item
const getItemTip = async function(token, projectId, itemId)
{ return await fetchDataFromAPS('https://developer.api.autodesk.com/data/v1/projects/' + projectId + '/items/' + itemId + '/tip', token); };

// Get info about an Autodesk user
const getUserInfo = async function(token)
{ return await fetchDataFromAPS('https://api.userprofile.autodesk.com/userinfo', token); };

module.exports = {
	getClientId : getClientId,
	request3LeggedAccessToken : request3LeggedAccessToken,
	regenerateAccessToken : regenerateAccessToken,
	revokeToken : revokeToken,
	startRevitExport : startRevitExport,
	getJobInfo : getJobInfo,
	getHubs : getHubs,
	getProjects : getProjects,
	getFolders : getFolders,
	getFolderContent : getFolderContent,
	getItemTip : getItemTip,
	getUserInfo : getUserInfo
};
