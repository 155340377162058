// Methods used for things related to ACC (Autodesk Construction Cloud)

// Saves access token to local storage
const storeAccessTokenData = function(accessTokenData) {
	if (isStorageSupported()) localStorage.setItem(Constants.AutodeskUserAccessTokenItemName, JSON.stringify(accessTokenData));
};

// Get ACC access token from local storage
const retrieveAccessTokenDataFromLocalStorage = function() {
	let accessTokenData = null;
	try {
		if (isStorageSupported()) {
			let tokenDataJSON = localStorage.getItem(Constants.AutodeskUserAccessTokenItemName);
			accessTokenData = JSON.parse(tokenDataJSON);
		}
	} catch (error) {
		console.error("Unexpected error while trying to retrieve access token from local storage: ", error);
	}
	return (accessTokenData !== null && accessTokenData !== '' && accessTokenData !== 'null') ? accessTokenData : null;
};

module.exports = {
	storeAccessTokenData : storeAccessTokenData,
	retrieveAccessTokenDataFromLocalStorage : retrieveAccessTokenDataFromLocalStorage
};
