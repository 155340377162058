"use strict";

import rvtFileIcon from '../icons/rvtfile.png';
import folderIcon from '../icons/folder.png';
import generalFileIcon from '../icons/generalFile.png';
import hubIcon from '../icons/hubIcon.png';
import projectIcon from '../icons/projectIcon.png';


module.exports = {
	rvtFileIcon : rvtFileIcon,
	folderIcon : folderIcon,
	generalFileIcon : generalFileIcon,
	hubIcon : hubIcon,
	projectIcon : projectIcon
};
