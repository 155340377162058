"use strict";

module.exports = {
	// Version of cloud system
	CurrentVersion : 1,
	EncryptionKeyItemName : "ArkioCloudPortalEncryptionKey", // Name of encryption key item in web storage
	MultipartNrOfParts : 10, // Number of parts for multipart upload
	EntryLifespan : 30*24*60*60*1000, // Time until entries in cloud expire in milliseconds

	// Time between verification attempts in milliseconds,
	// when verfiying that something got written to the cloud
	VerificationSleepTime : 2000,

	// Timeout in milliseconds when verifying that data got sent to the cloud
	VerificationTimeout : 5*60*1000,

	// Base URI for backend services for communicating with Autodesk Platform Services
	//APSBackendServicesBaseURI : 'http://localhost:3000',
	//APSBackendServicesBaseURI : 'https://arkio-bim360-test.herokuapp.com',
	APSBackendServicesBaseURI : 'https://arkio-bim360.herokuapp.com',

	AutodeskUserAccessTokenItemName : "ArkioCloudPortalAutodeskUserAccessToken", // Name of Autodesk user access token item in web storage

	// Icon strings in the cloud entries
	Icon : {
		Revit : 'Revit',
		Rhino : 'Rhino',
		SketchUp : 'SketchUp',
		Unity : 'Unity',
		Models : 'Models',
		Images : 'Images'
	}
};
